.dayOneBg{
    background-color: #02A1A4 !important;
}
.HomeButton{
    height: 40px;
    background-color: black;
    border-radius: 8px;
    position: fixed;
    z-index: 99;
    left: 33px;
    top: 20px;
}
.styleBtnhme{
    color: #ffffff;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 14px;
    padding-bottom: 14px;
    margin: 0px;
}
.styleBtnhme:hover{
    text-decoration: none;
    color: #FFCC00;
}
img.arrowStyle {
    height: 30px;
    margin-right: 8px;
}