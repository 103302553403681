@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;0,700;0,900;1,500;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* fonts main */

@font-face{
  font-family: 'Balgin';
  src:  url(/static/media/BalginDisplay.a893b8aa.ttf);
}
/* fonts main */
@font-face{
  font-family: 'Blimey_Bold';
  src:  url(/static/media/BlimeyBold.49d84f26.otf);
}
@font-face{
  font-family: 'Jaune_Black';
  src:  url(/static/media/jauneBlack.cd0ccb23.OTF);
}
/* Let's get this party started */
body::-webkit-scrollbar {
width: 10px;
}
body{
  background-color: #FFCC00 !important;
}
/* Track */
body::-webkit-scrollbar-track {
-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.438);
border-radius: 6px;
}

/* Handle */
body::-webkit-scrollbar-thumb {
border-radius: 6px;
background: #FFCC00; 
-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.301); 
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body, html{
    border: 10px solid #E83D71;
    background-color: #E83D71;
    height: auto;
    max-width: 100vw!important;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.NH7Banner{
    background-color: #e83d71;
}
.webBannerStyle{
    width: 100%;
    height: auto;
    margin-top: 14px;
}
.webBannerStylel{
    width: 100%;
    height: auto;
}
.relativeStyle{
    text-align: right;
    padding-top: 16px;
}
.fixedStyle{
    padding: 14px 30px;
    background-color: #fff;
    margin-left: auto;
    /* margin-right: auto; */
    background: #FBEECC;
    border: 4px solid #7E1725;
    border-radius: 8px;
    font-family: 'Jaune_Black';
    text-transform: uppercase;
    font-size: 14px;
    margin-right: 30px;
    letter-spacing: 0.08em;
    cursor: pointer;
    color: #801726;
    animation: zoom-in-zoom-out 2s ease-out infinite;
}
@keyframes zoom-in-zoom-out {
    0% {
    transform: scale(1, 1);
    }
    50% {
    transform: scale(1.2, 1.2);
    }
    100% {
    transform: scale(1, 1);
    }
    }
/* Second Fold Styles Starts */
.cityMainDiv{
    background: #02A1A4;
    border: 6px solid #801726;
    padding-top: 40px;
}
.alignLeft{
    text-align: left;
    margin-bottom: 10px;
}
.cityHeadMain{
    font-family: 'Blimey_Bold';
    font-size: 58px;
    line-height: 76px;
    letter-spacing: 0.04em;
    color: #FFCC00;
}
.citySubHead, .ctSubHead{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}
.cityTakeoversDA{
    font-family: 'Blimey_Bold';
    font-weight: 900;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 46px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #7F1626;
    margin-bottom: 48px;
}
.cityTakeoversDA:hover{
    text-decoration: none !important;
}
.hvrStyleTxt:hover{
    text-decoration: none !important;
}
.cityCard{
    padding-top: 45px;
}
.cityCardImg{
    max-width: 100%;
    height: auto;
    filter: drop-shadow(0px 20px 50px rgba(0, 0, 0, 0.226));
    transition: all .5s ease-out;
    border: 4px solid #000;
}
.cityCardImg:hover{
    transform: scale(1.07);
}
.heightAdjust{
    height: 352px;
}
.CardheightAdjust{
    max-width: 100%;
    height: auto;
    margin-bottom: 24px;
    filter: drop-shadow(0px 20px 50px rgba(0, 0, 0, 0.082));
    transition: all .5s ease-out;
}
.CardheightAdjust:hover{
    transform: scale(1.06);
}
.spaceBtn{
    margin-top: 40px;
}
.visitBtn{
    background: #FBEECC;
    border: 4px solid #7E1725;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: 'Jaune_Black';
    font-size: 16px;
    letter-spacing: 0.08em;
    color: #801726;
    width: 100%;
    padding: 14px 20px 18px 20px;
}
.visitBtn:hover{
    background-color: #FFCC00;
    text-decoration: none;
    transition: .3s all ease-in;
    color: #801726;
}
.bottomProp{
    padding-top: 100px;
}
.alignRight, .rightSmiley{
    text-align: right;
}
.alignLeftPropCity{
    text-align: left;
}
.propsSecond{
    max-width: 100%;
    height: auto;
}
/* Second Fold Styles Ends */

/* Third Fold Styles Starts */
.cityTakeoversDiv{
    background: #FFCC00;
    border: 6px solid #801726;
    padding: 100px 0px 0px 0px;
}
.spaceBottomSecond{
    margin-bottom: 20px;
}
.cityTakeoverContent{
    padding-bottom: 30px;
}
.ctHeading{
    font-family: 'Blimey_Bold';
    font-size: 60px;
    line-height: 72px;
    letter-spacing: 0.04em;
    color: #7F1626;
}
.ctSubHead{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #2c2c2c;
}
.ctNames{
    font-family: 'Jaune_Black';
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 0.06em;
    color: #2D2A2B;
}
.propsThird{
    max-width: 100%;
    height: auto;
}
.flashit{
    animation: flash linear 1.4s infinite;
}
@keyframes flash {
    0% { opacity: 1; } 
    50% { opacity: .1; } 
    100% { opacity: 1; }
}
/* Third Fold Styles Ends */

.guidelinesDiv{
    padding: 100px 0px 0px 0px;
    background: #EEDBA0;
    border: 6px solid #801726;
    box-sizing: border-box;
}

.glHeading{
    font-family: 'Blimey_Bold';
    font-size: 70px;
    line-height: 76px;
    text-align: left;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #E73F24;
    margin-bottom: 18px;
}
.Points{
    font-family: 'Roboto';
    font-weight: 800;
    font-size: 26px;
    line-height: 36px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #2D2A2B;
    text-transform: uppercase;
}
.footerSocial{
    text-align: left;
}
.animateSmiley{
    animation: up-down 3s ease-in-out infinite alternate-reverse both;
}
.leftDisplay{
    
}
@keyframes up-down {
    0% {
        transform: translate(0) rotate(
    0deg);
    }
    40% {
        transform: translateY(26px) rotate(
    .8deg);
    }
    80% {
        transform: translateY(-14px) rotate(
    -.4deg);
    }
    100% {
        transform: translate(0) rotate(
    0deg);
    }
}
.bottomGuidelines{
    padding-top: 0px;
}
  .ferrisWheelG{
    max-width: 100%;
    height: 180px;
    margin-right: 30px;
  }

  .footerDiv{
    background: #FBEBEC;
    border: 6px solid #801726;
    padding: 30px 0px 0px 0px;
  }
  .footerLogo{
      text-align: left;
  }
  .nodwinFooterLogo{
      text-align: right;
  }
  .footerLogo{
      max-width: 100%;
      height: 240px;
      text-align: center;
  }
  .NodwinfooterLogo{
      max-width: 100%;
      height: 70px;
      margin-top: 20px;
  }
  .footerSocial{
    font-family: 'Blimey_Bold';
    font-size: 55px;
    letter-spacing: 0.03em;
    color: #801726;
    margin-top: 10px;
    text-align: center;
  }
  .socialIcon{
      max-width: 100%;
      height: 32px;
      transition: all .3s ease-in-out;
  }
  .socialIcon:hover{
    transform: scale(1.2);
}
  .alignCenter{
      text-align: center;
  }
  .lastOne{
      margin-top: 50px;
  }
  .footerFerrisWheel{
      max-width: 100%;
      position: absolute;
        bottom: 0px;
        left: 100px;
        z-index: 9999;
        height: 234px;
  }
  .footerTxt{
    font-family: 'Roboto';
    font-weight: 500;
    letter-spacing: 0.03em;
    font-size: 14px;
    color: #000000;
  }
  .sandFooterD{
      position: absolute;
      bottom: 0px;
      max-width: 100%;
      height: auto;
      right: 14px;
  }
  .rightIconSpace{
      margin-right: 44px;
  }
  .bottomPropFooter{
    display: none;
}
.sandFooter, .footerFerrisWheelM{
    display: none;
}
.spaceBtnMiddle{
    margin-top: 50px;
}
.mobileViewCity{
    display: none;
}
.desktopViewCity{
    display: block;
}
@media only screen and (max-width: 1024px) {
    .footerFerrisWheel{
          left: 50px !important;
          height: 240px;
    }
    .visitBtn{
        font-size: 14px;
    }
    .cityHeadMain{
        line-height: 58px;
    }
}
@media only screen and (max-width: 1600px) {
    .footerFerrisWheel{
          left: 200px ;
          height: 280px;
    }
}
@media only screen and (max-width: 1680px) {
    .footerFerrisWheel{
          left: 220px ;
          height: 280px;
    }
}
@media only screen and (max-width: 1920px) {
    .footerFerrisWheel{
          left: 320px;
          height: 280px;
    }
}
@media only screen and (max-width: 1440px) {
    .footerFerrisWheel{
          left: 100px ;
          height: 280px;
    }
}
@media only screen and (max-width: 768px) {
    .footerFerrisWheel{
        max-width: 100%;
        position: absolute;
          bottom: 0px;
          left: 18px ;
          z-index: 9999;
          height: 208px;
    }
    .rightIconSpace{
        margin-right: 24px;
    }
    .footerSocial{
        margin-top: 0px;
    }
    .visitBtn{
        font-size: 10px;
        padding: 12px 10px;
    }
    .cityHeadMain{
        line-height: 58px;
    }
    .CardheightAdjust{
        height: auto;
        margin-bottom: 30px;
    }
    .mobileViewCity{
        display: block;
    }
    .desktopViewCity{
        display: none;
    }
    .cityCardImg{
        height: auto !important;
    }
}

  @media only screen and (max-width: 600px) {
    .cityHeadMain, .ctHeading{
        font-size: 44px;
        line-height: 50px;
        margin-bottom: 16px;
    }
    .citySubHead, .ctSubHead{
        font-size: 16px;
        line-height: 140%;
        font-weight: 400;
    }
    .animateSmiley{
        height: 50px;
        margin-bottom: 30px;
    }
    .cityMainDiv{
        padding-top: 50px;
    }
    .alignLeft{
        text-align: center;
    }
    .cityCardStyle {
        margin-bottom: 50px;
    }
    .visitBtn {
        border: 3px solid #7E1725;
        font-size: 14px;
        letter-spacing: 0.09em;
        color: #801726;
        padding: 12px 20px 16px 20px;
    }
    .cityTakeoversDiv{
        padding: 80px 0px 0px 0px;
    }
    .ctNames {
        font-size: 22px;
        line-height: 34px;
        letter-spacing: 0.06em;
    }
    .mView{
        align-self: center;
    }
    .glHeading{
        font-size: 60px;
    }
    .rightSmiley{
        text-align: left;
        margin-bottom: 20px;
    }
    .Points{
        font-size: 18px;
        line-height: 32px;
    }
    .guidelinesDiv{
        padding-top: 60px;
    }
    .ferrisWheelG{
        margin-right: 0px;
    }
    .footerLogo{
        text-align: center;
        margin-bottom: 0px;
    }
    .followUs {
        margin-bottom: 30px;
    }
    .footerSocial{
        font-size: 50px;
    }
    .nodwinFooterLogo{
        text-align: center;
    }
    .footerFerrisWheel{
        height: 180px;
        margin-bottom: 40px;
    }
    .footerTxt{
        position: relative;
        font-size: 12px;
        padding: 0px 10px;
    }
    .bottomPropFooter{
        display: block;
    }
    .sandFooterD, .mViewFooter{
        display: none;
    }
    .sandFooter{
        display: block;
        max-width: 100%;
        position: absolute;
        bottom: -3px;
        right: 12px;
    }
    .footerFerrisWheelM{
        display: block;
    }
    .alignLeftProp{
        position: relative;
    }
    .footerFerrisWheelM{
        height: 180px;
        max-width: 100%;
    }
    .footerSocial{
        text-align: center;
    }
    body, html{
        border: 5px solid #E83D71 !important;
        background-color: #E83D71 !important;
    }
   .mViewLineUp{
        display: block !important;
    }
    .LineUpHeading{
        font-family: 'Blimey_Bold';
        font-size: 60px !important;
        line-height: 60px !important;
        letter-spacing: 0.04em;
        color: #ffffff;
        margin-bottom: 0px !important;
    }
    .puneLineUp{
        padding-bottom: 30px;
        padding-top: 54px !important;
    }
    .delhiLineUp{
        padding-top: 30px !important;
        margin-top: 58px;
    }
    .KolkataLineUpMview{
        display: block !important;
        position: relative;
    }
    .KolkataLineUpDesktop{
        display: none !important;
    }
    .fixedStyle{
        padding: 12px 12px;
        background-color: #fff;
        width: 120px;
        margin-left: auto;
        margin-right: auto;
        background: #FBEECC;
        border: 1px solid #7E1725;
        /* box-sizing: border-box; */
        border-radius: 8px;
        font-family: 'Jaune_Black';
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 0.08em;
        color: #801726;
        animation: zoom-in-zoom-out 2s ease-out infinite;
    }
    .cityTakeoversDAM{
        font-family: 'Blimey_Bold';
        font-weight: 900;
        text-transform: uppercase;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #7F1626;
        margin-bottom: 38px;
    }
    .relativeStyle{
        padding-top: 8px;
    }
  }

  .KolkataLineUpDesktop{
    display: block;
    position: relative;
    padding-top: 50px;
}
.KolkataLineUpMview{
    display: none ;
}

  @media (min-width: 75em){
    .car-animation {
        animation: carMove 13s infinite linear;
    }
    .carSecond-animation {
        animation: carMoveSecond 13s infinite linear;
    }
    }
    @media (min-width: 60em){
        .car-animation {
            animation: carMove 15s infinite linear;
        }
        .carSecond-animation {
            animation: carMoveSecond 15s infinite linear;
        }
    }
    @media (min-width: 48em){
    .car-animation {
        animation: carMove 10s infinite linear !important;
    }
    .carSecond-animation {
        animation: carMoveSecond 10s infinite linear !important;
    }
    }
    .car-animation {
        animation: carMove 15s infinite linear;
    }
    .carSecond-animation {
        animation: carMoveSecond 15s infinite linear;
    }
    .car, .car img {
        width: 168px;
        height: auto;
        margin-top: -28px;
    }
    .carSecond, .carSecond img {
        width: 208px;
        height: auto;
        margin-top: -35px;
    }
    @keyframes carMove {
        0% {
            transform: translate(0vw, 0);
        }
        100% {
            transform: translate(100vw, 0);
        }
    }
    @keyframes carMoveSecond {
        0% {
            transform: translate(100vw, 0);
        }
        100% {
            transform: translate(0vw, 0);
        }
    }

    .lineUpDiv{
        /* padding: 80px 0px; */
        background: linear-gradient(180deg, #A7B100 36.46%, rgba(193, 205, 0, 0.7) 100%);
        border: 6px solid #801726;
    }
    .photosDiv{
        /* padding: 80px 0px; */
        background: #FFCC00;
        border: 6px solid #801726;
        padding: 80px 0px;
    }
    .puneCardLineup, .kolkataCardLineup, .delhiCardLineup{
        max-width: 100%;
        height: auto;
    }
    .puneLineUp{
        padding-top: 49px;
        position: relative;
    }
    .puneA{
        position: absolute;
        z-index: 12;
        height: 80px;
    }
    .puneB{
        position: absolute;
        z-index: 12;
        height: 54px;
        left: -19px;
        bottom: 18%;
    }
    .kolkataA{
        position: absolute;
        z-index: 12;
        height: 60px;
        right: -28px;
        top: 15%;
    }
    .kolkataB{
        position: absolute;
        z-index: 12;
        height: 52px;
        left: 55px;
        top: 21%;
    }
    .kolkataAm{
        position: absolute;
        z-index: 12;
        height: 60px;
        right: -23px;
        top: 6%;
    }
    .kolkataBm{
        position: absolute;
        z-index: 12;
        height: 52px;
        left: 55px;
        top: 14%;
    }
    .kolkataLotusB {
        position: absolute;
        z-index: 12;
        height: 108px;
        max-width: 100%;
        left: -58px;
        top: 15%;
    }
    .kolkataLotusA {
        position: absolute;
        z-index: 12;
        height: 108px;
        max-width: 100%;
        right: -48px;
        top: 10%;
    }
    .kolkataLotusBm {
        position: absolute;
        z-index: 12;
        height: 64px;
        max-width: 100%;
        left: -28px;
        top: 9%;
    }
    .kolkataLotusAm {
        position: absolute;
        z-index: 12;
        height: 70px;
        max-width: 100%;
        right: 20px;
        top: 1%;
    }
    .delhiA{
        position: absolute;
        z-index: 12;
        height: 52px;
        right: 45px;
        top: 7%;
    }
    .smileyDelhi{
        position: absolute;
        z-index: 12;
        height: 65px;
        right: 45px;
        top: -2%; 
    }
    .delhiB{
        position: absolute;
        z-index: 12;
        height: 52px;
        right: -24px;
        top: 40%;
    }
    .delhiLineUp{
        padding-top: 55px;
        position: relative;
    }
    .LineUpHeading{
        font-family: 'Blimey_Bold';
        font-size: 76px;
        line-height: 68px;
        letter-spacing: 0.04em;
        color: #ffffff;
        margin-bottom: 0px;
    }
    .mViewLineUp{
        display: none;
    }
    .zoom-in-out-box {

        animation: zoom-in-zoom-out 4s ease infinite;
      }
      
      @keyframes zoom-in-zoom-out {
        0% {
          transform: scale(1, 1);
        }
        50% {
          transform: scale(1.2, 1.2);
        }
        100% {
          transform: scale(1, 1);
        }
      }
      .zoom-in-out-boxx {

        animation: zoom-in-zoom-outt 4s ease infinite;
      }
      
      @keyframes zoom-in-zoom-outt {
        0% {
          transform: scale(1.1, 1.1);
        }
        50% {
          transform: scale(1.2, 1.2);
        }
        100% {
          transform: scale(1.02, 1.02);
        }
      }

      .spacePhotoDiv{
          margin-bottom: 50px;
      }
.bgStyle{
    background-color: #FFCC00;
}
.txtCard2{
    font-family: 'Blimey_Bold';
    font-size: 48px;
    color: #1b1b1b;
    line-height: 54px;
    margin-bottom: 32px;
    letter-spacing: .02em;
    text-align: left;
}
.cardHome{
    text-align: center;
    height: 300px;
    justify-content: center;
    width: 400px;
    background-color: #FFCC00;
    margin-left: auto;
    margin-right: auto;
    border: 8px solid #000000;
    align-items: center;
    display: flex;
}
.center{
    text-align: center;
}
.spaceBottom{
    padding-top: 100px;
}
.txtCard{
    font-family: 'Blimey_Bold';
    font-size: 62px;
    color: #ffffff;
    line-height: 68px;
    margin-bottom: 42px;
    letter-spacing: .02em;
    text-align: center;
}
.styleImage{
    max-width: 100%;
    height: auto;
}
.firstImage{
    margin-bottom: 38px;
}
@media only screen and (max-width: 600px) {
    .spaceBottomM{
        margin-bottom: 30px;
    }
    .spaceBottom{
        margin-top: 80px;
    }
    .txtCard{
        font-family: 'Blimey_Bold';
        font-size: 52px;
        color: #ffffff;
        line-height: 58px;
    }
    .txtCard2Center{
        font-family: 'Blimey_Bold';
        font-size: 52px !important;
        color: #ffffff;
        line-height: 58px !important;
    }
}
.HomeButton{
    height: 40px;
    background-color: black;
    border-radius: 8px;
    position: fixed;
    z-index: 99;
    left: 33px;
    top: 20px;
}
.styleBtnhme{
    color: #ffffff;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 14px;
    padding-bottom: 14px;
    margin: 0px;
}
.styleBtnhme:hover{
    text-decoration: none;
    color: #FFCC00;
}
img.arrowStyle {
    height: 30px;
    margin-right: 8px;
}
.txtCard2Center{
    font-family: 'Blimey_Bold';
    font-size: 62px;
    color: #101010;
    line-height: 68px;
    margin-bottom: 42px;
    letter-spacing: .02em;
    text-align: center;
}
.dayOneBg{
    background-color: #02A1A4 !important;
}
.HomeButton{
    height: 40px;
    background-color: black;
    border-radius: 8px;
    position: fixed;
    z-index: 99;
    left: 33px;
    top: 20px;
}
.styleBtnhme{
    color: #ffffff;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 14px;
    padding-bottom: 14px;
    margin: 0px;
}
.styleBtnhme:hover{
    text-decoration: none;
    color: #FFCC00;
}
img.arrowStyle {
    height: 30px;
    margin-right: 8px;
}
