.bgStyle{
    background-color: #FFCC00;
}
.txtCard2{
    font-family: 'Blimey_Bold';
    font-size: 48px;
    color: #1b1b1b;
    line-height: 54px;
    margin-bottom: 32px;
    letter-spacing: .02em;
    text-align: left;
}
.cardHome{
    text-align: center;
    height: 300px;
    justify-content: center;
    width: 400px;
    background-color: #FFCC00;
    margin-left: auto;
    margin-right: auto;
    border: 8px solid #000000;
    align-items: center;
    display: flex;
}
.center{
    text-align: center;
}
.spaceBottom{
    padding-top: 100px;
}
.txtCard{
    font-family: 'Blimey_Bold';
    font-size: 62px;
    color: #ffffff;
    line-height: 68px;
    margin-bottom: 42px;
    letter-spacing: .02em;
    text-align: center;
}
.styleImage{
    max-width: 100%;
    height: auto;
}
.firstImage{
    margin-bottom: 38px;
}
@media only screen and (max-width: 600px) {
    .spaceBottomM{
        margin-bottom: 30px;
    }
    .spaceBottom{
        margin-top: 80px;
    }
    .txtCard{
        font-family: 'Blimey_Bold';
        font-size: 52px;
        color: #ffffff;
        line-height: 58px;
    }
    .txtCard2Center{
        font-family: 'Blimey_Bold';
        font-size: 52px !important;
        color: #ffffff;
        line-height: 58px !important;
    }
}
.HomeButton{
    height: 40px;
    background-color: black;
    border-radius: 8px;
    position: fixed;
    z-index: 99;
    left: 33px;
    top: 20px;
}
.styleBtnhme{
    color: #ffffff;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 14px;
    padding-bottom: 14px;
    margin: 0px;
}
.styleBtnhme:hover{
    text-decoration: none;
    color: #FFCC00;
}
img.arrowStyle {
    height: 30px;
    margin-right: 8px;
}
.txtCard2Center{
    font-family: 'Blimey_Bold';
    font-size: 62px;
    color: #101010;
    line-height: 68px;
    margin-bottom: 42px;
    letter-spacing: .02em;
    text-align: center;
}